/* eslint-disable react/no-danger */
import React, { useContext, useState, useEffect } from 'react';
// import { confirmAlert } from 'react-confirm-alert';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import styled from 'styled-components';
import { ContextProps, GlobalContext } from '../../context/global-context';
import { getProductBuyData } from '../../data/product';
import Viabill from './viabill';
import Countdown from './countdown';
import Button from '../formitems/button';
import { logBDEvent } from '../../utils/tracking';
import { useOrderStore } from '../../data/orders';
import { toast } from 'react-toastify';
import { fbTrack } from 'utils/fbpixel';
import { BuySectionProduct, FullProduct } from 'data/types';
import Loader from 'components/core/load-spinner';
import { AiOutlineShopping } from 'react-icons/ai';
import ProductNewsletter from 'components/core/product-newsletter';

type BuySectionProps = {
  product: FullProduct;
};

const BuySection: React.FC<BuySectionProps> = ({ product }) => {
  const { dictionary, setPopupState, shop } =
    useContext<ContextProps>(GlobalContext);
  const router = useRouter();
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingBuyButton, setLoadingBuyButton] = useState<boolean>(false);
  const [hasError, setHasError] = useState<string | null>(null);
  const currency = useOrderStore((state) => state?.order?.basket?.currency);
  const [selectedVariants, setSelectedVariants] = useState(null);
  const [productData, setProductData] = useState<BuySectionProduct>(product);
  const { asPath, locale } = router;
  const addToBasket = useOrderStore((state) => state.addToBasket);
  const baseUrl = `https://${shop.domain}`;
  const variantSku = router.query?.variantSku as string;

  useEffect(() => {
    const getData = async (): Promise<void> => {
      setLoading(true);
      const data = await getProductBuyData(
        product.slug,
        asPath,
        locale,
        selectedVariants || {}
      );
      if (data?.variants) {
        const mainVariant = data.variants.find(
          (variant) => variant.type === 'main'
        );
        if (
          mainVariant &&
          selectedVariants?.[mainVariant._id] !== mainVariant.selected
        ) {
          setSelectedVariants({
            ...(selectedVariants || {}),
            [mainVariant._id]: mainVariant.selected,
          });
        }
      }
      if (data) {
        setProductData(data);
      }
      setLoading(false);
    };
    getData();
  }, [currency, product.slug, asPath, selectedVariants, locale]);

  const {
    _id,
    price,
    inStock,
    millisecondsLeftToPreorder,
    confirmMessage,
    shippingStartDate,
    beforePriceFormatted,
    priceFormatted,
    variants,
    name,
    preorder,
  } = productData;

  const onBuyClick = async (): Promise<void> => {
    setHasError(null);
    setLoadingBuyButton(true);
    const res = await addToBasket(
      {
        amount: 1,
        productId: _id,
        selected: selectedVariants,
      },
      { locale: router.locale, path: router.asPath }
    );
    if (res.success) {
      const { exactSku, totalAmount } = res.data; // eslint-disable-line
      fbTrack('AddToCart', {
        content_ids: exactSku,
        contents: [
          {
            id: exactSku,
            quantity: 1,
            item_price: Number(price.toFixed(2)),
          },
        ],
        content_type: 'product',
        content_name: name,
        currency,
        value: Number((price * 1).toFixed(2)),
      });
      logBDEvent('add-to-cart', exactSku, {
        name,
        amount: 1,
        price,
        currency,
      });
      setPopupState('checkout');
    } else {
      const { message, data } = res;

      toast(message, { type: 'error' });

      if (data?.focus) {
        setHasError(data.focus);
      }
    }
    setLoadingBuyButton(false);
  };

  // PreOrder / InStock / OutOfStock / PreSale / SoldOut
  let availability = 'https://schema.org/InStock';
  if (preorder) {
    availability = 'https://schema.org/PreOrder';
  }
  if (!inStock) {
    availability = 'https://schema.org/OutOfStock';
  }

  return (
    <StyledBuySection className={`buy-section ${loading ? 'loading' : ''}`}>
      {beforePriceFormatted && (
        <div className="before h3 light skel skal-s">
          {beforePriceFormatted}
        </div>
      )}
      <div className="price h2 light skel skel-m">{priceFormatted}</div>
      {variants && variants.length > 0 && (
        <>
          <div className="variants">
            {variants.map((variant) => (
              <div
                className={classNames(
                  'variants-block',
                  { 'has-error': hasError === variant._id },
                  {
                    hide:
                      variant.type === 'main' &&
                      variant.attributes.length === 1 &&
                      (!selectedVariants || selectedVariants?.[variant._id]),
                  }
                )}
                key={variant._id}
              >
                <div className="label skel skel-s">{variant.name}</div>
                <div className="attributes-block">
                  {variant.attributes.map((attr) => (
                    <button
                      type="button"
                      key={attr.sku}
                      className={`attribute skel ${
                        attr.selected ? 'selected' : ''
                      } ${attr.inStock ? 'in-stock' : 'not-in-stock'} ${
                        attr.color ? 'has-color' : 'has-text'
                      }`}
                      style={{ backgroundColor: attr.color }}
                      onClick={() => {
                        if (variant.type === 'main') {
                          const pathArr = asPath.split('/');
                          pathArr[pathArr.length - 1] = attr.sku.toLowerCase();
                          const url = pathArr.join('/');
                          if (url !== asPath) {
                            router.push(url);
                          }
                        } else {
                          if (hasError === variant._id) {
                            setHasError(null);
                          }
                          setSelectedVariants({
                            ...selectedVariants,
                            [variant._id]: attr.sku,
                          });
                        }
                      }}
                    >
                      {!attr.color && attr.name}
                    </button>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </>
      )}
      {!inStock ? (
        <div>
          <hr />
          <ProductNewsletter productId={_id} />
        </div>
      ) : (
        <>
          <div className="buy">
            <div>
              <Button
                className="buy-button skel"
                size="large"
                disabled={loading || loadingBuyButton}
                onClick={onBuyClick}
                loading={loadingBuyButton}
                icon={AiOutlineShopping}
                alignIcon="left"
                confirmText={confirmMessage}
              >
                {preorder
                  ? dictionary.addPeorderToBasket
                  : dictionary.addToBasket}{' '}
                - {priceFormatted}
              </Button>
            </div>
          </div>

          {millisecondsLeftToPreorder && (
            <div className="preorder-info">
              <div className="days-left">
                <Countdown millisecondsLeft={millisecondsLeftToPreorder} />
              </div>
              <div className="shipping-start">
                {dictionary.shippingStarts} {shippingStartDate}
              </div>
            </div>
          )}
        </>
      )}
      {inStock &&
        currency === 'DKK' &&
        process.env.NEXT_PUBLIC_SHOP_SLUG !== 'finite' && (
          <Viabill view="product" price={price} />
        )}
      {loading && <Loader />}
      <hr />

      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify({
            '@context': 'https://schema.org/',
            '@type': 'Product',
            name: product.metaTitle || product.name,
            image: product.images.map(
              (img) => `http://beatdown.imgix.net${img.src}`
            ),
            description: product.metaDescription,
            brand: 'Beatdown Merchandise',
            sku: `${product.sku}${
              variantSku ? `_${variantSku.toUpperCase()}` : ''
            }`,
            offers: {
              '@type': 'Offer',
              url: `${baseUrl}/p/${product.slug}${
                variantSku ? `/${variantSku}` : ''
              }`,
              priceCurrency: shop.defaultCurrency || currency || 'DKK',
              price: price.toFixed(2),
              availability,
              itemCondition: 'https://schema.org/NewCondition',
            },
          }),
        }}
      />
    </StyledBuySection>
  );
};
const StyledBuySection = styled.div`
  padding: 2rem 0 0 0;
  position: relative;

  .before {
    color: var(--base-dimmed-foreground);
    text-decoration: line-through;
    margin: var(--space-between-lines-margin);
    white-space: nowrap;
    opacity: 0.5;
  }

  .h1.price {
    margin: var(--space-between-lines-margin);
    white-space: nowrap;
    margin: 0 0 0.5rem 0;
  }

  .preorder-info {
    margin: 1rem 0;

    .shipping-start {
      font-size: 0.8rem;
      margin: 0.5rem 0;
      color: var(--secondary-background);
    }
  }
  .variants {
    margin: 2rem 0;

    .variants-block {
      &.hide {
        display: none;
      }

      &.has-error {
        border: 1px solid var(--error-foreground);
        border-radius: 4px;
        padding: 0 1rem;
        margin-left: -1rem;
        margin-right: -1rem;

        .label {
          color: var(--error-foreground);
        }
      }
    }

    .label {
      font-size: 0.9rem;
      font-weight: 600;
      letter-spacing: -0.5px;
      margin: 1rem 0 1rem 0;
      line-height: 1rem;
      /* color: var(--primary-background); */
      text-transform: uppercase;
    }
    .attributes-block {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;
      margin: 0 0 2rem 0;

      .attribute {
        min-height: 2rem;
        min-width: 2rem;
        border: 1px solid var(--grey);
        margin: 0 0.5rem 0.5rem 0;
        font-size: 0.8rem;
        font-weight: bold;

        &.has-text {
          padding: 4px 12px;
          border-radius: 100px;

          &.selected {
            background: var(--primary-background);
            border-color: var(--primary-background);
            color: var(--primary-foreground);
          }
        }

        &.not-in-stock {
          position: relative;
          opacity: 0.4;
          &:before {
            position: absolute;
            content: '';
            left: 50%;
            top: 0;
            bottom: 0;
            border-left: 1px solid;
            transform: rotate(45deg);
          }

          &.has-color {
            opacity: 0.3;
          }
        }

        &.has-color {
          border-radius: 50%;

          &.selected {
            box-shadow: 0 0 0 4px var(--primary-background);
            border-color: var(--primary-background);
          }
        }
      }
    }
  }

  .buy-button {
    margin: 1.5rem 0;
  }

  > .load-wrapper {
    transform: scale(0.5);
    position: absolute;
    bottom: 0.5rem;
    right: 0.5rem;
  }
`;
export default BuySection;
