import Button from 'components/formitems/button';
import Input from 'components/formitems/input';
import { ContextProps, GlobalContext } from 'context/global-context';
import React, { FC, useContext, useState } from 'react';
import styled from 'styled-components';
import { useForm, Controller } from 'react-hook-form';
import { useOrderStore } from 'data/orders';
import { useRouter } from 'next/router';
import { isEmail } from 'utils/helpers';
import { newsletterSignup } from 'data/shop';

export type NewsletterSignupProps = {
  productId?: string;
  tags?: string[];
  mode: 'interested-in-product' | 'newsletter';
};

export const NewsletterSignup: FC<NewsletterSignupProps> = ({
  mode,
  productId,
  tags,
}) => {
  const { dictionary } = useContext<ContextProps>(GlobalContext);
  const { asPath, locale } = useRouter();
  const [submitting, setSubmitting] = useState(false);
  const [signedUp, setSignedUp] = useState(false);
  const defaultEmail = useOrderStore((state) => {
    return state.order?.email || '';
  });

  const { handleSubmit, errors, control } = useForm({
    reValidateMode: 'onChange',
    criteriaMode: 'firstError',
    shouldFocusError: true,
    shouldUnregister: true,
  });

  const onSubmit = async ({ email }): Promise<void> => {
    setSubmitting(true);
    const res = await newsletterSignup({
      email: email,
      mode,
      productId,
      path: asPath,
      locale,
      tags,
    });

    if (res) {
      setSignedUp(true);
    }
    setSubmitting(false);
  };

  return (
    <StyledNewsletterSignup>
      {signedUp ? (
        <div className="signed-up">
          <h3>{dictionary.signedUpHeader}</h3>
          <div>{dictionary.signedUpBody}</div>
        </div>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="form-item">
            <Controller
              render={({ onChange, value, name }) => (
                <Input
                  onChange={onChange}
                  name={name}
                  value={value}
                  placeholder={dictionary.email}
                  errorMessage={errors.email?.message || ''}
                  autoComplete="shipping email"
                />
              )}
              name="email"
              rules={{
                required: dictionary.validationInvalidEmail,
                validate: (val) => {
                  if (isEmail(val)) {
                    return true;
                  }
                  return dictionary.validationInvalidEmail;
                },
              }}
              control={control}
              defaultValue={defaultEmail}
            />
          </div>
          <Button htmlType="submit" disabled={submitting}>
            {dictionary.signup}
          </Button>
        </form>
      )}
    </StyledNewsletterSignup>
  );
};

export const StyledNewsletterSignup = styled.div`
  .signed-up {
    margin: 1rem 0;
    font-style: italic;
  }

  margin: 1rem 0;
  .form-item {
    margin: 1rem 0;
  }
`;

export default NewsletterSignup;
