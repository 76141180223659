import { ContextProps, GlobalContext } from 'context/global-context';
import { useContext } from 'react';
import styled from 'styled-components';

import NewsletterSignup from './newsletter-signup';

export type ProductNewsletterProps = {
  productId: string;
};

export const ProductNewsletter: React.FC<ProductNewsletterProps> = ({
  productId,
}) => {
  const { dictionary } = useContext<ContextProps>(GlobalContext);

  return (
    <StyledProductNewsletter>
      <h3>{dictionary.soldOut}</h3>
      <div>{dictionary.soldOutBody}</div>
      <NewsletterSignup mode="interested-in-product" productId={productId} />
    </StyledProductNewsletter>
  );
};

export const StyledProductNewsletter = styled.div`
  margin: 1rem 0;
  .form-item {
    margin: 1rem 0;
  }
`;

export default ProductNewsletter;
