import { fetchAPI } from './fetch-api-data';
import { FullPage, ListProduct, UrlItem } from './types';
import cache from 'memory-cache';
import { getToken } from 'utils/helpers';
import { ParsedUrlQuery } from 'querystring';
import { fullProductQuery, listProductItemQuery } from './product';

// Henter alt page data
export const getPage = async (
  path: string,
  locale: string,
  extraHeaders: Record<string, unknown> = {}
): Promise<FullPage> => {
  const query = `
    query {
      page {
        _id
        slug
        metaDescription
        metaKeywords
        metaTitle
        metaImage
        name
        type
        breadcrumb {
          _id
          type
          name
          href
        }
        items {
          _id
          type
          params {
            product {
              ${fullProductQuery}
            }
            products
            tags
            quicklinks {
              _id
              header
              link
              image
            }
            image
            imagesDesktop
            imagesMobile 
            buttonText
            link
            fullHeight
            cssClassName
            header
            body
            feed {
              id
              src
              caption
              width
              height
              url
            }
            countdownMillisecondsLeft
          }
        }
        products ${listProductItemQuery}
        settings {
          splashPage
          cssClassName
          customStyles
          backgroundImageDesktop
          backgroundImageMobile
        }
      }
    }
  `;

  const pageData = await fetchAPI(query, {
    variables: {},
    headers: { path, locale, ...extraHeaders },
  });

  const { page } = pageData;
  const { items, products, ...rest } = page;
  // Opdater items product id'er med rigtige produkter
  // og strip products fra data
  const updatedItems = items.map((item) => {
    if (item.type === 'products') {
      item.params.products = item.params.products
        .map((_id) => products.find((p) => p._id === _id))
        .filter((p) => !!p);
    }
    return item;
  });
  return { ...rest, items: updatedItems };
};

// Bruges kun til at skabe URL'er
export const getProductsForPage = async (
  path: string
): Promise<ListProduct[]> => {
  const cacheKey = `getProductsForPage-${path}`;
  if (cache.get(cacheKey)) {
    return cache.get(cacheKey);
  }

  const query = `
    query {
      page {
        products {
          _id
          url
          slug
          variants {
            _id
            type 
            attributes {
              _id
              sku
            }
          }
        }
      }
    }
  `;
  const pageData = await fetchAPI(query, {
    variables: {},
    headers: { path, locale: 'da' }, // Locale kan være ligegyldigt... Vi bruger det kun til  at skabe URL'er
  });
  const {
    page: { products },
  } = pageData;

  cache.put(cacheKey, products, process.env.CI ? 10 * 60 * 1000 : 5000);

  return products;
};

export const add404Page = async ({
  path,
  query,
  locale,
}: {
  path: string;
  query: ParsedUrlQuery;
  locale: string;
}): Promise<string> => {
  if (!process.browser) {
    throw new Error('addToBasket called from server');
  }
  const mutation = `
    mutation($query: JSON) { 
      add404Page(query: $query) 
    }
  `;
  const mutationData = await fetchAPI(mutation, {
    variables: { query },
    headers: { locale, path, token: getToken() },
  });
  return mutationData.add404Page;
};

export const getUrls = async (): Promise<UrlItem[]> => {
  const query = `
    query {
      urls {
        type
        updated
        url
        priority
      }
    }
  `;
  const data = await fetchAPI(query, {
    variables: {},
    headers: { path: '/', locale: 'da' }, // Locale kan være ligegyldigt... Vi bruger det kun til  at skabe URL'er
  });

  return data.urls;
};
