import Head from 'next/head';
import Layout from '../layout/master-layout';
import styled from 'styled-components';
import React, { useContext, useEffect } from 'react';
import { ContextProps, GlobalContext } from 'context/global-context';
import { useRouter } from 'next/router';
import { add404Page } from 'data/page';
import SearchResult from 'components/core/searchresult';
import { unslugify } from 'unslugify';

export type PageNotFoundProps = {};  // eslint-disable-line

export const PageNotFound: React.FC<PageNotFoundProps> = () => {
  const { dictionary } = useContext<ContextProps>(GlobalContext);
  const { query = {}, asPath, locale, push } = useRouter();

  useEffect(() => {
    const sendData = async (): Promise<void> => {
      const redirect = await add404Page({ path: asPath, locale, query });
      if (redirect) {
        push(redirect);
      }
    };
    sendData();
  }, [asPath, locale, query]); // eslint-disable-line

  let searchQueryPath = '';
  const pathArr = asPath
    .toLowerCase()
    .split('/')
    .filter((key) => !!key);
  if (pathArr.length > 0) {
    if (pathArr.indexOf('p')) {
      searchQueryPath = pathArr[pathArr.indexOf('p') + 1];
    }
    if (!searchQueryPath) {
      searchQueryPath = pathArr[pathArr.length - 1];
    }
  }

  return (
    <Layout cssClassName="404">
      <Head>
        <title>{dictionary.pageNotFoundHead}</title>
      </Head>
      <StyledPageNotFound>
        <div className="inner-headers">
          <h1 className="display">{dictionary.pageNotFoundHead}</h1>
          <p>{dictionary.pageNotFoundBody}</p>
        </div>

        {searchQueryPath && (
          <SearchResult query={unslugify(searchQueryPath)} simple />
        )}
      </StyledPageNotFound>
    </Layout>
  );
};
const StyledPageNotFound = styled.main`
  .inner-headers {
    min-height: calc(50vh - var(--navigation-height));
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    max-width: 800px;
    margin: auto;
    padding: 2rem var(--side-padding);

    h1 {
      margin-top: 0;
    }
  }
`;

export default PageNotFound;
