import Head from 'next/head';
import Layout from '../layout/master-layout';
import styled from 'styled-components';
import React, { useContext } from 'react';
import Loader from 'components/core/load-spinner';
import { ContextProps, GlobalContext } from 'context/global-context';

export type LoadingPageProps = {
  cssClassName: string;
  simple?: boolean;
  textDictKey: string;
};

export const LoadingPage: React.FC<LoadingPageProps> = ({
  cssClassName,
  simple = false,
  textDictKey = '',
}) => {
  const { dictionary } = useContext<ContextProps>(GlobalContext);
  if (dictionary) {
    return (
      <Layout cssClassName={cssClassName} simple={simple}>
        <Head>
          <title>{dictionary?.[textDictKey] || 'Loading...'}</title>
        </Head>

        <StyledLoadingPage>
          <Loader title={dictionary?.[textDictKey] || 'Loading...'} />
        </StyledLoadingPage>
      </Layout>
    );
  }

  return (
    <div style={{ height: '100vh' }}>
      <Head>
        <title>{dictionary?.[textDictKey] || 'Loading...'}</title>
      </Head>

      <StyledLoadingPage className="fixed">
        <Loader />
      </StyledLoadingPage>
    </div>
  );
};
const StyledLoadingPage = styled.main`
  min-height: calc(100vh - var(--navigation-height));
  display: flex;
  justify-content: center;
  align-items: center;

  &.fixed {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
`;

export default LoadingPage;
